import React from "react";
import Layout from "../components/_App/layout";
import SEO from "../components/_App/seo"; 
import Navbar from "../components/_App/Navbar";
import Footer from "../components/_App/Footer";
import PageBanner from '../components/Common/PageBanner'; 
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemPanel,
    AccordionItemButton
} from 'react-accessible-accordion';

const FAQ = () => (
    <Layout>
        <SEO title="FAQ" /> 

        <Navbar />

        <PageBanner pageTitle="FAQ" />

        <div className="faq-area ptb-80">
            <div className="container">
                <div className="faq-accordion">
                    <Accordion allowZeroExpanded preExpanded={['a']}>
                        <AccordionItem uuid="a">
                            <AccordionItemHeading>
                                <AccordionItemButton>
                                    <span>
                                        Des offres gratuites ?
                                    </span>
                                </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                                <p>
                                    Oui. Mais dans la limite des stocks disponibles. Uniquement pour les élèves et étudiants, une preuve de scolarité est exigée mais n'est pas conservée.
                                Pour souscrire, envoyez les documents à contact@fenuahosting.com et c'est parti !
                                </p>
                            </AccordionItemPanel>
                        </AccordionItem>

                        <AccordionItem uuid="b">
                            <AccordionItemHeading>
                                <AccordionItemButton>
                                    <span>
                                        Comment vous mettre admin/OP de votre serveur de jeu?
                                    </span>
                                </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                                <p>
                                    Tout se passe dans votre panel utilisateur, une entrée vous permet d'utiliser les commandes pour vous octroyer les privilèges d'admin.
                                </p>
                            </AccordionItemPanel>
                        </AccordionItem>

                        <AccordionItem uuid="c">
                            <AccordionItemHeading>
                                <AccordionItemButton>
                                    <span>
                                        Quels sont les moyens de paiement acceptés?
                                    </span>
                                </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                                <p>
                                    Vous pouvez payer par carte VISA ou MASTERCAD via PayPal. Les prix sont convertis en euro car paypal ne gère pas le franc pacifique.
                                    Il est également possible de régler par virement. D'autres passerelles de paiement seront bientôt disponibles.
                                    Contactez-nous pour plus d'information.
                                </p>
                            </AccordionItemPanel>
                        </AccordionItem>
                    
                        <AccordionItem uuid="d">
                            <AccordionItemHeading>
                                <AccordionItemButton>
                                    <span>
                                        Comment se faire rembourser?
                                    </span>
                                </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                                <p>
                                    Vous êtes en droit de vous retracter durant 14 jours après votre commande.
                                    Pour exercer ce droit, contactez nous.
                                </p>
                            </AccordionItemPanel>
                        </AccordionItem>

                        <AccordionItem uuid="e">
                            <AccordionItemHeading>
                                <AccordionItemButton>
                                    <span>
                                        Quels sont les panels utilisés?
                                    </span>
                                </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                                <p>
                                    Nous utilisons des panels adaptés à nos différents services. Le panel Pterodactyl pour les serveur de jeux et un panel custom pour l'hébergement Web.
                                </p>
                            </AccordionItemPanel>
                        </AccordionItem>
                    </Accordion>
                </div>
            </div>
        </div>

        <Footer />
    </Layout>
)

export default FAQ;